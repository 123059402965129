export default {
  siteTitle: 'Get bonus',
  blogerName: 'GODMOTA',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/godmota/about'
    },
    {
      name: 'telegram',
      url: 'https://t.me/godmotav2'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/motahenr'
    },
    {
      name: 'website',
      url: 'https://godmota.pt/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/cdd8493ee',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c5fc0650b',
      gameTitle: 'Savage Buffalo Spirit Megaways (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c3edc3c1c',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/ca4be5c73',
      gameTitle: 'Doors Of Sol (BGaming)',
    }
  ],
  headerText: 'Regista-te com o código promocional <strong>GODMOTA</strong> e recebe 125 rodadas grátis em cada jogo disponível em baixo!',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'GODMOTA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>125FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
